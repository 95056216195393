import Footer from "molecules/Footer";
import React from "react";

import HeaderBase from "./../../organisms/HeaderBase";
import HeaderSecondary from "./../../organisms/HeaderSecondary";
import { footerLinks, socialLinks, contactUs } from './../../constant';

const LayoutFullScreen = ({ className = '', children, isHeaderHiddenForMob, isShowFooter = true, noSecondaryHeader = false,
    isWithSticky = true }) => {
    return (
        <div className="flex flex-col min-h-screen">
            <HeaderBase className={`${isHeaderHiddenForMob ? "hidden" : ""} md:flex sticky w-full top-0 left-0`} isMobileInnerPage={isHeaderHiddenForMob} />
            {
                noSecondaryHeader ?
                    (
                        <div id="content" className={`${className} ${isHeaderHiddenForMob ? 'md:pt-5 md:pb-5' : ''}`}>{children}</div>
                    )
                    :
                    (
                        <>
                            <HeaderSecondary isFullscreen className={`${isHeaderHiddenForMob ? "hidden" : " "} ${isWithSticky ? 'md:sticky w-full md:top-19 md:left-0' : ''}`} />
                            <div id="content" className={`${className} ${isHeaderHiddenForMob ? 'md:pt-5 md:pb-5' : ''} grow`}>{children}</div>
                        </>
                    )
            }

            {isShowFooter && <Footer footerLinks={footerLinks} socialLinks={socialLinks}
                address={contactUs.address} mobileNo={contactUs.mobileNo} email={contactUs.email} isMobileInnerPage={isHeaderHiddenForMob} />}
        </div>
    );
};

export default LayoutFullScreen;
