import LayoutFullScreen from "@/components/Layout/LayoutFullScreen";
import { getBlogsCatgeoriesAndListing } from "@/components/blog/BlogListingApiHandlers";
import Head from "next/head";
import Loading from 'components/Loader/Loading';
import dynamic from 'next/dynamic';
import { BLOG_TYPE, VALID_BLOG_URL } from "constant";
const BlogListing = dynamic(() => import('@/components/blog/BlogListing'), { ssr: false, loading: () => <Loading type='layer' /> });
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function index({ status, message, categories, blogs, count, path, mediaType ,response, error, validUrl}) {
    const router = useRouter();   
    useEffect(() => {
        if(error){
            router.push("/404")
        }
    }, [error])
    
    return ( !error &&
        <div>
            <Head>
                {/* <link rel="stylesheet" href={`${process.env.NEXT_PUBLIC_WIDGET_BASE}/makeappointment/MakeAppointmentWidget.css`} /> */}
            </Head>

            <LayoutFullScreen isWithSticky={false}>
                <BlogListing blogs={blogs} categories={categories} showCategories={true} showFeatured={true} path={path} mediaType={mediaType} showSubHeading={true} />
            </LayoutFullScreen>
        </div>
    );
};

export async function getServerSideProps({ params }) {
    let validUrl = VALID_BLOG_URL.includes(params?.mediaType)
    const props = {
        status: true,
        message: '',
        categories: [],
        blogs: [],
        count: 0,
        path: "",
        mediaType:0,
        params,
        validUrl,
        error:false
    };
    if(validUrl){
        const mediaType = BLOG_TYPE[params?.mediaType]
        const response = await getBlogsCatgeoriesAndListing("", mediaType);
        props.response=response
        props.mediaType = mediaType
        props.path = params?.mediaType
        props.params = params

    if (!response.status) {
        props.status = response.status;
        props.message = response.message;
    } else {
        props.status = response.status;
        props.message = response.message || null;
        props.blogs = response.rows;
        props.categories = response.categories;
        props.count = response.count;
    }
    }else{
        props.error =true
    }
    return { props };
};